module.exports = {
    seo: {
        title : "Jenko - Article - Mass Production",
        description:  ""
    },
    intro : {
        title : "Mass Production"
    },
    articleHeader : {
        title: "From Development to\u00A0Production",
        desc : "The chain of operations that take a great idea for a product to a product that sits on store shelves includes several steps. Initially, developing the concept and testing its nature in terms of engineering, marketing and of course financially. Then, we perform a characterization and feasibility study. After that, the idea becomes meat and bones by creating a prototype and if you choose to do so, it can also be patented. Next, build a marketing strategy, business plan and everything is needed to attract investors. Once there is capital involved, you can start progressing quickly and preparing for\u00A0mass\u00A0production."
    },
    secP: {
        title: "How is it actually\u00A0done?",
        p:[
             "The production market can be divided into two parts: individual production and mass production. Individual production usually refers to unique items such as custom woodwork that you order from a carpenter or a unique piece of jewelry from a designer. Mass production, on the other hand, is just as it sounds. A method that is suitable for producing identical items that we want to produce in exceptionally large quantities. We have many technologies available to help us with the production work. ",
              "Some examples of commonly used production processes:"
        ],
          addP:  [ 
               "3D Printing – allow you to create three-dimensional parts from CAD models by building materials layer by layer until a complete physical part is formed.",
               "CNC Machining – includes mills, lathes, and other computer-controlled subtractive processes. ",
               "Polymer Casting – a reactive liquid resin or rubber fills a mold which reacts chemically and solidifies.",
               "Rotational Molding – involves heating a hollow mold filled with powdered thermoplastic and rotated around two axes to produce mainly large hollow objects.",
               "There are also other processes such as Vacuum Forming, Injection Molding, Extrusion, Blow Molding and others."]
      },
    thrdP: {
        title: "Mass production costs",
        p: [
          "When talking about mass production costs, you first must understand the path to get there. Factories that supply mass production services are available worldwide today. Your product can be manufactured in Israel, in Europe, in the United States or in the East. China attracts most of the manufacturing attention, thanks to the variety it offers in terms of rates and quality levels. So how do you choose? The selection process of the factory and the mass production process in general is usually accompanied by a company that specializes in this. ",
          "The first step you will undertake will be a thorough market survey aimed at selecting as accurate a manufacturer as possible for the project. Next, the company will pass the product along with production requirements to the factory’s team and will be in close contact with them to ensure that the service you receive is optimal. At the next stage, we establish the production lines, start the production process and from there all that is left is packaging, assembly, and shipment to and from the country, if needed. Casting the raw materials into molds allows production in commercial quantities. Creating the molds, then, is a critical step in the production process. It is very worthwhile to invest generously at this point because quality templates are the guarantee of a high-quality product. The raw materials they cast into the molds are usually the cheaper part. The costs depend on the type of product, method of production and output amount."
       ] 
      },
    frthP : {
        title: "Manufacturing plants in\u00A0China ",
        p:  "In the last thirty years, China has become the number one manufacturer in the world because of the uncompetitive tariffs offered by the factories there. Over the years, some of these factories have begun to lower their prices, which restored American and European players. China offers a wide range of quality options and a wide range of prices, accordingly. If you choose to work with China, it is particularly important to be accompanied by an experienced person working with the Chinese. This is important both to find a suitable manufacturer for a project that is both high quality and inexpensive and for the ongoing conduct with the Chinese which also requires knowledge and experience. Also, an experienced person will know how to comply with the required standards and regulations. It is especially important to be in constant control and monitor throughout the process to ensure quality production with minimum failures. Examples of everyday products made in China: laptops, mobile phones, clothing and footwear, electrical appliances, kitchen equipment and more."
       },
    ffthP : {
        title: "Production using plastic\u00A0injection",
        p: [
        "Plastic is one of the most popular production materials today. Usually when you say ‘plastic’ in everyday language, you refer to hard plastic products, but the range of plastic types is broad. To illustrate, an asthmatic inhaler, a supermarket bag, a CD, a two-gallon milk container, are all plastic products. Plastic includes a broad spectrum of synthetic and semi-synthetic materials that are easy to manufacture thanks to the ability to easily shape them by heating and applying pressure.",
            "A plastic injection molding process is the same as the one we already detailed in the previous paragraphs. First, create templates or molds tailored to the chosen product. Then choose the raw material from which the product is produced. Plastic injection molded plastic products include electrical appliances, medical equipment, decorative and furniture accessories, toys and more."
      ] 
    },
    dir : 'ltr'
};